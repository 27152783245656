html, body {
  margin: 0; padding: 0; border: 0; outline: 0;
  box-sizing: border-box;
  font-family: -apple-system, Graphik, ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: auto !important ;
}

*, *:before, *:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Graphik, EBGaramond, Montserrat, Monaco, Consolas, 'Courier New',
    monospace;
}


 @font-face {
  font-family: "MontserratItalic";
  src: local("MontserratItalic"),
   url("./assets/fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: 'italic';
 } 

 
 
 @font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./assets/fonts/Montserrat.ttf") format("truetype");
   font-weight: 500;
 } 

 @font-face {
  font-family: "MontserratLight";
  src: local("Montserrat"),
   url("./assets/fonts/Montserrat.ttf") format("truetype");
   font-weight: 200;
 } 

 
 @font-face {
  font-family: "EBGaramond";
  src: local("EBGaramond"),
   url("./assets/fonts/EBGaramond.ttf") format("truetype");
  font-weight: 500;
  font-style: 'normal';
 } 
 
 @font-face {
  font-family: "EBGaramondLight";
  src: local("EBGaramond"),
   url("./assets/fonts/EBGaramond.ttf") format("truetype");
  font-weight: 200;
  font-style: 'normal';
 } 
