.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.use-case-container {
  align-items: flex-start;
  justify-content: center;
}

.use-case-card {
  background-color: rgba(224, 238, 255, 0.7);
  backdrop-filter: blur(25px);
}

.font-graphik-bold {
  font-family: 'Montserrat';
}

.font-graphik-semibold {
  font-family: 'Montserrat';
}

.font-graphik-medium {
  font-family: 'Montserrat';
}

.font-graphik-regular {
  font-family: "Montserrat";
}

.font-graphik-light {
  font-family: "MontserratLight";
}

.font-graphik-regular-italic {
  font-family: "MontserratItalic";
}

.font-graphik-semibold-italic {
  font-family: "MontserratItalic";
}

.genai-icon-add-style {
  box-sizing: contemt-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ra_chat_bot_card:hover {
  border-width: 4px;
  border-style: solid;
  border-image:
    linear-gradient(to bottom,
      #FF50A0, #A100FF, #7500C0, #00FFFF) 1 100%;
  border-right: none;
  background: rgba(227, 233, 239, 0.35);
}

.genai-icon-close:hover {
  border-width: 1px;
  border-color: #FFF;
  border-style: solid;
  border-radius: 18px;
  background: rgba(231,233,235);
  cursor: pointer;
}

.genai-select-option:hover {
  background-color: rgba(204, 227, 255)!important;
}